class Bootstrapper {
  addPolyfills () {
    // For iterating over NodeLists with forEach
    if (window.NodeList && !window.NodeList.prototype.forEach) {
      window.NodeList.prototype.forEach = Array.prototype.forEach;
    }

    if (!window.Element.prototype.matches) {
      window.Element.prototype.matches = window.Element.prototype.msMatchesSelector ||
        window.Element.prototype.webkitMatchesSelector;
    }

    if (!window.Element.prototype.closest) {
      window.Element.prototype.closest = function (s) {
        let el = this;

        do {
          if (el.matches(s)) {
            return el;
          }

          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
      };
    }
  }

  /* eslint-enable */
  addGlobals () {
    window.IGLOO = window.IGLOO || {
      bbout_element_id: 'blackbox',
      enable_atrip: false,
      loader: {
        uri_hook: '/account/iojs',
        version: 'general5'
      }
    };

    window.IGNITE = window.IGNITE || {};

    // Analytics object
    window._SFDDL = window._SFDDL || {};

    window.cdApi = window.cdApi || {};
  }

  bootstrap () {
    this.addPolyfills();
    this.addGlobals();
  }
}

new Bootstrapper().bootstrap();
